// Leaflet is loaded via base.html so its plugins work
// require('leaflet'); ... require('leaflet.fullscreen');


$(document).ready(function () {
	const imageRoot = '/static/js/vendor/leaflet/images/';

	// Custom icon to ensure image paths are correct on staging & production (somehow, there is no issue on local)
	//
	// If the path is incorrect, a marker seems to show twice on staging:
	// - it's actually a marker and its shadow
	// - the shadow image isn't found and replaced by the default image
	const blueIcon = L.icon({
		iconUrl: `${imageRoot}marker-icon.png`,
		iconRetinaUrl: `${imageRoot}marker-icon-2x.png`,
		shadowUrl: `${imageRoot}marker-shadow.png`,
		iconSize: [25, 41],
		iconAnchor: [12, 41],
		popupAnchor: [1, -34],
		tooltipAnchor: [16, -28],
		shadowSize: [41, 41]
	});

	// Regular maps
	$('.js-leaflet-map').each(function (index, element) {
		const lat = parseFloat($(element).data('lat'));
		const lng = parseFloat($(element).data('lng'));
		const zoom = parseInt($(element).data('zoom'));
		const mapboxToken = $(element).data('mapbox-token');

		const map = L.map(element.id, {
			fullscreenControl: { pseudoFullscreen: false }
		}).setView([lat, lng], zoom);
		L.tileLayer(
			'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={mapboxToken}',
			{ attribution: null, id: 'mapbox.streets', mapboxToken }
		).addTo(map);

		L.marker([lat, lng], {icon: blueIcon}).addTo(map);
	});

	// remove Leaflet mention
	$('.leaflet-control-attribution').hide();
});
